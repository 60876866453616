<template>
  <div class="eventcontent">
    <div class="columns mt-6 mb-6">
      <div class="column mt-6 mb-6">
        <ValidationObserver ref="observer" v-slot="{ passes }" v-if="isEntry">
          <div class="container kontaktdaten has-text-left">
            <div class="columns is-multiline">
              <div class="coloumn column is-8">
                <ValidationProvider
                  rules="required"
                  name="thema"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    label="Dein Themenvorschlag"
                    :type="{ 'has-text-white': errors[0], 'is-success': valid }"
                    custom-class="has-text-white"
                    :message="errors"
                  >
                    <b-input custom-class="is-large" v-model="thema"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="coloumn column is-4">
                <b-field label="von*" custom-class="has-text-white">
                  <b-input custom-class="is-large" v-model="absender"></b-input>
                </b-field>
                <p class="labeldescription">*optional</p>
              </div>
            </div>
          </div>
          <b-button
            type="is-info"
            size="is-large"
            class="spacetop"
            @click="passes(themaabsenden)"
            :loading="isLoadingButton"
            >Thema eintragen</b-button
          >
        </ValidationObserver>

        <b-notification
          type="is-twitter"
          aria-close-label="Close notification"
          :closable="false"
          class="is-size-6"
          v-if="isSuccess"
        >
          Danke für den Themenvorschlag! Beim nächsten TechWednesday werden alle
          Themen kurz vorgestellt, dann entscheidet das Publikum, wie es
          weitergeht. Wir freuen uns schon!
        </b-notification>
        <b-notification
          type="is-twitter"
          aria-close-label="Close notification"
          :closable="false"
          class="is-size-6"
          v-if="isError"
        >
          Hier ist gerade etwas schief gegangen - bitte versuche es später noch
          einmal.
        </b-notification>
      </div>
      <div class="column mt-5 mb-6">
        <p class="subtitle has-text-white mb-4">
          Folgende Themen wurden schon vorgeschlagen:
        </p>
        <b-notification :closable="false" type="is-info">
          <div class="themenliste">
            <b-notification
              type="is-info"
              aria-close-label="Close notification"
              :closable="false"
              class="is-size-6"
              v-if="data.eingereicht.results.length == 0"
            >
              Leider wurde noch kein Thema vorgeschlagen - du kannst es
              jederzeit ändern!
            </b-notification>
            <p v-if="isLoadingTechWednesday">
              <b-skeleton height="18px" width="55%"></b-skeleton>
            </p>
            <p v-if="isLoadingTechWednesday">
              <b-skeleton height="18px" width="45%"></b-skeleton>
            </p>
            <p v-if="isLoadingTechWednesday">
              <b-skeleton height="18px" width="76%"></b-skeleton>
            </p>
            <div
              v-for="eingereicht in data.eingereicht.results"
              :key="eingereicht.id"
            >
              <p class="is-size-5">
                {{ eingereicht.properties.Thema.rich_text[0].plain_text }}
                <span
                  class="is-size-7"
                  v-if="
                    eingereicht.properties.Absender.rich_text[0].plain_text !=
                    ''
                  "
                >
                  von
                  {{ eingereicht.properties.Absender.rich_text[0].plain_text }}
                </span>
              </p>
            </div>
          </div>
        </b-notification>
      </div>
    </div>
    <h3 class="is-size-4 is-size-5-mobile mt-6">
      Diese Themen gab es bisher beim TechWednesday:
    </h3>
    <ul v-if="data.vorgetragen.results.length != 0">
      <li v-if="isLoadingTechWednesday">
        <b-skeleton height="18px" width="25%"></b-skeleton>
      </li>
      <li v-if="isLoadingTechWednesday">
        <b-skeleton height="18px" width="45%"></b-skeleton>
      </li>
      <li v-if="isLoadingTechWednesday">
        <b-skeleton height="18px" width="76%"></b-skeleton>
      </li>
      <li v-if="isLoadingTechWednesday">
        <b-skeleton height="18px" width="52%"></b-skeleton>
      </li>
      <li v-if="isLoadingTechWednesday">
        <b-skeleton height="18px" width="25%"></b-skeleton>
      </li>
      <li
        class="is-size-6"
        v-for="vorgetragen in data.vorgetragen.results"
        :key="vorgetragen.id"
      >
        {{ vorgetragen.properties.Thema.rich_text[0].plain_text }}
      </li>
    </ul>
  </div>
</template>

<script type="text/babel">
import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("required", {
  ...required,
  message: "Dieses Feld muss ausgefüllt werden.",
});

extend("min", {
  ...min,
  message: "Bitte trage mehr Zeichen ein.",
});

export default {
  name: "CurrentTechWEdnesday",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      data: {
        eingereicht: {
          results: {},
        },
        vorgetragen: {
          results: {},
        },
      },
      closenotificationEvent: false,
      isLoadingButton: false,
      isErrorEvent: false,
      notionpagesize: 25,
      date: null,
      thema: "",
      absender: "",
      isLoadingTechWednesday: true,
      isError: false,
      isSuccess: false,
      isEntry: true,
    };
  },
  props: {
    notionpage: String,
  },
  methods: {
    getTheDate: function () {
      const currentYear = new Date().getFullYear();
      const currentDay = new Date().getDate();
      const currentMonth = new Date().getMonth();
      const today = new Date(currentYear, currentMonth, currentDay);
      const FirstDayOfThisMonth = new Date(currentYear, currentMonth, 1);
      const FirstThisMonthWeekday = FirstDayOfThisMonth.getDay();
      const FirstWednesdayThisMonth = this.getFirstWednesday(
        FirstDayOfThisMonth,
        FirstThisMonthWeekday
      );
      const FirstDayOfNextMonth = new Date(
        FirstDayOfThisMonth.setMonth(FirstDayOfThisMonth.getMonth() + 1)
      );
      const FirstNextMonthWeekday = FirstDayOfNextMonth.getDay();
      var TechWednesdayDate;
      if (FirstWednesdayThisMonth >= today) {
        TechWednesdayDate = FirstWednesdayThisMonth;
      } else {
        TechWednesdayDate = this.getFirstWednesday(
          FirstDayOfNextMonth,
          FirstNextMonthWeekday
        );
      }
      return TechWednesdayDate;
    },
    getNextDate: function (date) {
      const FirstDayOfNextTechWednesdayMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        1
      );
      const FirstNextTechWednesdayMonthWeekday =
        FirstDayOfNextTechWednesdayMonth.getDay();
      const NextTechWednesday = this.getFirstWednesday(
        FirstDayOfNextTechWednesdayMonth,
        FirstNextTechWednesdayMonthWeekday
      );
      return NextTechWednesday;
    },
    getFirstWednesday: function (FirstDayOfMonth, FirstMonthWeekday) {
      // Sonntag: 0, Montag: 1, Dienstag: 2, Mittwoch: 3, Donnerstag: 4, Freitag: 5, Samstag: 6
      var addDay;

      if (FirstMonthWeekday === 0) {
        //Sonntag
        addDay = 3;
      } else if (FirstMonthWeekday === 1) {
        //Montag
        addDay = 2;
      } else if (FirstMonthWeekday === 2) {
        //Dienstag
        addDay = 1;
      } else if (FirstMonthWeekday === 3) {
        //Mittwoch
        addDay = 0;
      } else if (FirstMonthWeekday === 4) {
        //Donnerstag
        addDay = 6;
      } else if (FirstMonthWeekday === 5) {
        //Freitag
        addDay = 5;
      } else if (FirstMonthWeekday === 6) {
        //Samstag
        addDay = 4;
      } else {
        //Fallback
        addDay = 7;
      }
      const Day = FirstDayOfMonth.getDate();
      const Month = FirstDayOfMonth.getMonth();
      const Year = FirstDayOfMonth.getFullYear();
      const WednesdayDay = Day + addDay;
      const returnDate = new Date(Year, Month, WednesdayDay);
      return returnDate;
    },
    async getData() {
      try {
        const res = await axios.post(
          ".netlify/functions/readtechwednesday",
          JSON.stringify({
            PageID: this.notionpage,
            PageSize: this.notionpagesize,
            TechWednesday: this.date,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.data = res.data;
          this.isLoadingTechWednesday = false;
        } else {
          //could not get Data
          this.isLoadingTechWednesday = false;
          this.isErrorEvent = true;
        }
      } catch (error) {
        //console.log("ERROR:", error);
        this.isLoadingTechWednesday = false;
        this.isErrorEvent = true;
      }
    },
    async themaabsenden() {
      this.isLoadingButton = true;
      var NextTechWednesday;
      if (this.date > new Date()) {
        NextTechWednesday = this.date;
      } else {
        NextTechWednesday = this.getNextDate(this.date);
      }
      try {
        const res = await axios.post(
          ".netlify/functions/writetechwednesday",
          JSON.stringify({
            subject: this.thema,
            author: this.absender,
            date: NextTechWednesday,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.isSuccess = true;
          this.isEntry = false;
          this.isLoadingButton = false;
          this.getData();
        } else {
          //could not get Data
          this.isError = true;
          this.isEntry = false;
        }
      } catch (error) {
        //console.log("ERROR:", error);
        this.isError = true;
        this.isEntry = false;
      }
    },
  },
  created() {
    this.date = this.getTheDate();
    this.$emit("techwednesdaydate", this.date);
    this.getData();
  },
};
</script>

<style lang="scss">
.eventbutton {
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  border-left: 1px solid white;
  transition: all 0.3s ease;
  & h2 {
    transition: all 0.3s ease;
  }
}
.eventbutton:hover {
  padding-left: 3rem;
  border-left: 5px solid white;
  color: $info;
  & h2 {
    color: $info;
  }
}
.eventcontent {
  min-height: 10rem;
}
</style>
