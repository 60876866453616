<template>
  <div class="notioncontent">
    <div class="container content">
      <Meta :title="title" :description="description" />
      <FullPageContent :notiondata="data" />
      <BlockContent @notiondata="apidata" :notionpage="NotionPageID" />
      <h2 class="is-size-3 is-size-4-mobile mt-6">
        Nächster Termin &amp; Themenvorschläge
      </h2>
      <article role="alert" class="notification is-info">
        <div class="media">
          <div class="media-left">
            <img
              class="icon is-large is-size-4 is-size-5-mobile mt-1"
              :src="require(`@/assets/Icons/calendar.svg`)"
            />
          </div>
          <div class="media-content is-size-6">
            Der nächste TechWednesday findet am 3. Mai 2023
            <!--{{ TechWednesdayDate | moment("DD.MM.YYYY") }}-->
            statt.<br />Wir starten um <b>18:00</b>, die Veranstaltung dauert
            <b>90 Minuten</b> und findet
            <b v-if="data.length != 0">{{
              data.properties.TechWednesdayRaum.rich_text[0].plain_text
            }}</b>
            statt.
          </div>
        </div>
      </article>
      <p class="is-size-6">
        Wir versuchen den TechWednesday immer in Hybrid durchzuführen. Wenn du
        also nicht auf den Campus kommen kannst, dann wähle dich gerne digital
        ein:
      </p>
      <p class="is-size-6">
        <a
          href="https://hs-flensburg.webex.com/hs-flensburg/j.php?MTID=m62f6b2ea6248a58b7b36b21c07b51dde"
          target="_blank"
          class="is-size-6 button is-medium is-primary is-outlined is-inverted"
        >
          <span class="icon is-small">
            <i class="mdi mdi-link-variant"></i>
          </span>
          &nbsp;&nbsp;&nbsp;zum Webex-Raum
        </a>
      </p>
      <CurrentTechWednesday
        @techwednesdaydate="TechWednesday"
        :notionpage="NotionTechWednesdayID"
      />
    </div>
  </div>
</template>

<script type="text/babel">
import Meta from "@/components/seo/Meta.vue";
import FullPageContent from "@/components/notion/FullPageContent.vue";
import BlockContent from "@/components/notion/BlockContent.vue";
import CurrentTechWednesday from "@/components/notion/CurrentTechWednesday.vue";

export default {
  name: "TechWednesday",
  components: {
    Meta,
    FullPageContent,
    BlockContent,
    CurrentTechWednesday,
  },
  data() {
    return {
      NotionPageID: "2ea34e19bcb94c4a98cd91c1fb67451b",
      NotionTechWednesdayID: "9f79b7e6e5a641b082992e756373f1bb",
      TechWednesdayDate: null,
      title: "Realisiere deine Idee",
      description:
        "TeStUp - Das StartUp-Village für technologische Produkte der Hochschule Flensburg unterstützt beim realisieren von Ideen.",
      data: [],
    };
  },
  methods: {
    apidata: function (value) {
      this.data = value;
    },
    TechWednesday: function (value) {
      this.TechWednesdayDate = value;
    },
  },
};
</script>

<style lang="scss"></style>
